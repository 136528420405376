<template>
  <div class="projects_search">
    <Navigation />
    <Breadcrumb :breadcrumb="state.listBreadcrumb" />
    <ProjectSearchPreloader v-if="state.preload" />
    <div class="projects_search__container container" v-if="!state.preload">
      <div class="projects_search__container__left left-side">
        <div class="project_search__filter">
          <div class="project_search__filter__container">
            <div class="project_search__filter__container__category">
              <div class="project_search__filter__container__category__title" @click="onToggleFilter('categories')">
                {{ state.aLang.US015_filter_category_title }}
                <span class="project_search__filter__container__category__title__polygon" v-show="state.toggleFilter.categories"></span>
                <span class="project_search__filter__container__category__title__polygon__close" v-show="!state.toggleFilter.categories"></span>
              </div>
              <div
                class="project_search__filter__container__category__container"
                v-show="state.toggleFilter.categories"
              >
                <div
                  class="form-ctrl"
                  v-for="(category, idx) in state.projectCategories"
                  :key="idx"
                >
                  <div class="form-group">
                    <input
                      type="checkbox"
                      :name="'category_' + category.id"
                      :id="'category_' + category.id"
                      :value="category.id"
                      v-model="state.filter.categories"
                      @change="onChangeFilterCategories"
                    />
                    <label :for="'category_' + category.id">
                      {{ category.name }}
                    </label>
                  </div>
                </div>
              </div>
              <div class="project_search__filter__container__category__name" v-show="!state.toggleFilter.categories">
                {{ state.filterConditionName.categories }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="projects_search__container__right main-area">
        <div class="projects_search__container__right__keyword_search">
          <div class="form-ctrl">
            <div class="form-group">
              <input
                type="text"
                :placeholder="
                  '&#xF002; ' + state.aLang.US015_filter_keyword_placeholder
                "
                v-model="state.keyword"
                @input="onChangeKeyword"
              />
            </div>
          </div>
        </div>
        <div class="projects_search__container__right__table">
          <div class="projects_search__container__right__table__title">
            {{ state.aLang.US015_table_title }}
          </div>
          <div class="projects_search__container__right__table__pagination">
            {{ state.listProjects.length
            }}{{ state.aLang.US015_table_paginator_current }} /
            {{ state.totalProjects
            }}{{ state.aLang.US015_table_paginator_total }}　
            {{ state.aLang.US015_table_paginator_limit }}：
            <a
              class="offset"
              :class="{ active: state.projectPerPage == 10 }"
              @click="changeProjectPerPage(10)"
              >10</a
            >
            ｜
            <a
              class="offset"
              :class="{ active: state.projectPerPage == 50 }"
              @click="changeProjectPerPage(50)"
              >50</a
            >
          </div>
          <div class="projects_search__container__right__table__detail">
            <table>
              <thead>
                <tr>
                  <th>
                    {{ state.aLang.US015_table_column_image }}
                  </th>
                  <th>
                      {{ state.aLang.US015_table_column_organization_name }}
                  </th>
                  <th>
                    <a
                      :class="{
                        active:
                          state.fieldSortName == 'title' &&
                          state.fieldSortType == 'asc',
                      }"
                      @click="sortTable('title')"
                      >{{ state.aLang.US015_table_column_title }}</a
                    >
                  </th>
                  <th>
                    <a
                      :class="{
                        active:
                          state.fieldSortName == 'category_name' &&
                          state.fieldSortType == 'asc',
                      }"
                      @click="sortTable('category_name')"
                      >{{ state.aLang.US015_table_column_category }}</a
                    >
                  </th>
                  <th>
                    {{ state.aLang.US015_table_column_data_types }}
                  </th>
                </tr>
              </thead>
              <tbody v-if="state.listProjects.length">
                <tr
                  v-for="project of state.listProjects"
                  :key="project.project_id"
                >
                  <td>
                    <img
                        @error="replaceByDefault"
                        :src="project.organization_image || noPhoto"
                        :alt="project.title"
                    />
                  </td>
                  <td>
                      {{ project.organization_name }}
                  </td>
                  <td>
                      <router-link :to="routePath.PROJECT_DETAIL + project.project_id">{{
                      project.title
                    }}</router-link>
                  </td>
                  <td>
                    {{ project.category_name }}
                  </td>
                  <td v-text="dataTypesString(project)"></td>
                </tr>
              </tbody>
            </table>
            <button
              v-show="
                state.listProjects.length < state.totalProjects &&
                state.listProjects.length > 0
              "
              class="btn-s btn-next-light-blue shadow"
              @click="loadMoreProjects"
              :disabled="state.loading"
            >
              <i v-if="state.loading" class="fas fa-circle-notch fa-spin"></i>
              {{ state.aLang.US015_table_load_more_btn }}
            </button>
          </div>
          <div
            class="projects_search__container__right__table__detail__no_data"
            v-if="state.listProjects.length == 0 && !state.preload"
          >
            {{ state.aLang.project_search_table_no_data }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" src="@/presentation/views/project/search/Search.ts" />
<link type="scss" src="@/presentation/views/project/search/Search.scss" />
