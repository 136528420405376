import { reactive, defineComponent, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import LanguageUtil from "@/commons/LanguageUtil";
import Breadcrumb from "@/presentation/components/breadcrumb/Breadcrumb.vue";
import Navigation from "@/presentation/components/navigation/Navigation.vue";
import RoutePath from "@/commons/RoutePath";
import ProjectSearchPreloader from "@/presentation/components/preloader/project-search-preloader/ProjectSearchPreloader.vue";
import DIContainer from "@/core/DIContainer";
import ProjectInteractor from "@/domain/usecases/ProjectInteractor";
import TransformUtil from "@/commons/TransformUtil";
import noPhoto from "@/assets/images/common/error_project.png";
import DatePicker from "@/presentation/components/datepicker/DatePicker.vue";
export default defineComponent({
    name: "ProjectSearch",
    components: {
        Breadcrumb: Breadcrumb,
        Navigation: Navigation,
        ProjectSearchPreloader: ProjectSearchPreloader,
        DatePicker: DatePicker
    },
    setup: function () {
        var store = useStore();
        var router = useRouter();
        var routePath = RoutePath;
        var state = reactive({
            aLang: LanguageUtil.getInstance().lang,
            listBreadcrumb: [
                {
                    route: '/',
                    name: "ダッシュボード"
                },
                {
                    route: RoutePath.PROJECT_DETAIL + 'search/',
                    name: "プロジェクト一覧"
                }
            ],
            projectCategories: [],
            listProjects: [],
            totalProjects: 0,
            projectPerPage: 10,
            page: 1,
            fieldSortName: "",
            fieldSortType: "",
            status: "",
            keyword: "",
            filter: {
                categories: [],
            },
            toggleFilter: {
                categories: true,
            },
            filterConditionName: {
                categories: '選択なし',
            },
            maxStartDate: null,
            minEndDate: null,
            loading: false,
            preload: true
        });
        var timeout = null;
        var projectInteractor = DIContainer.instance.get(ProjectInteractor);
        function onChangeKeyword(event) {
            state.page = 1;
            state.keyword = event.target.value;
            if (timeout)
                clearTimeout(timeout);
            timeout = setTimeout(function () {
                getListProjects("getNew");
            }, 1000);
        }
        function onChangeStatus(event) {
            state.page = 1;
            state.status = event.target.value;
            getListProjects("getNew");
        }
        function onChangeFilterStartDate(event) {
            state.page = 1;
            state.minEndDate = event !== null && event !== void 0 ? event : null;
            setTimeout(function () {
                getListProjects("getNew");
            }, 100);
        }
        function onChangeFilterEndDate(event) {
            state.page = 1;
            state.maxStartDate = event !== null && event !== void 0 ? event : null;
            setTimeout(function () {
                getListProjects("getNew");
            }, 100);
        }
        function onChangeFilterCategories() {
            state.page = 1;
            getListProjects("getNew");
        }
        function changeProjectPerPage(number) {
            state.projectPerPage = number;
            localStorage.setItem("projectPerPage", number);
            state.page = 1;
            getListProjects("getNew");
        }
        function sortTable(field) {
            if (field === state.fieldSortName) {
                state.fieldSortType = state.fieldSortType === "desc" ? "asc" : "desc";
            }
            else {
                state.fieldSortType = "asc";
            }
            state.fieldSortName = field;
            state.page = 1;
            getListProjects("getNew");
        }
        function getListCategories() {
            return projectInteractor.getProjectCategories()
                .then(function (result) {
                state.projectCategories = result.data;
            })
                .catch(function (error) {
            });
        }
        /**
         * フィルターを閉じた際に選択中の要素に応じて表示する名称を取得する
         *
         * @param list フィルターの全選択肢の一覧
         * @param selected 選択中の要素のidの一覧
         * @returns 選択中の要素が一つ以上存在する場合はそれらを'、'でjoinした文字列、
         *          一つも存在しない場合は'選択なし'
         */
        function getFilterName(list, selected) {
            return selected.length ?
                list.filter(function (item) { return selected.includes(item.id); })
                    .map(function (item) { return item.name; })
                    .join('、')
                : '選択なし';
        }
        /**
         * フィルターを閉じる際に呼び出されるイベント。
         * 選択肢の表示をトグルする。
         *
         * @param target イベント呼び出し元のフィルター種別名称
         */
        function onToggleFilter(target) {
            var name = '';
            switch (target) {
                case 'categories':
                    name = getFilterName(state.projectCategories, state.filter.categories);
                    break;
            }
            state.filterConditionName[target] = name;
            state.toggleFilter[target] = !state.toggleFilter[target];
        }
        function loadMoreProjects() {
            state.page++;
            getListProjects("loadMore");
        }
        function getListProjects(action) {
            var params = {
                "keyword": state.keyword,
                "order[column]": state.fieldSortName,
                "order[type]": state.fieldSortType,
                "limit": state.projectPerPage,
                "page": state.page,
                "filter[status]": state.status ? [state.status] : null,
                "filter[categories]": state.filter.categories ? state.filter.categories : null,
            };
            if (action == "loadMore") {
                state.loading = true;
            }
            return projectInteractor.searchProject(params).then(function (result) {
                if (!result.data || result.total_count == 0) {
                    state.listProjects = [];
                    state.totalProjects = 0;
                    state.loading = false;
                    state.preload = false;
                    return;
                }
                ;
                result.data.forEach(function (project) {
                    project.reward = "\u00A5" + TransformUtil.formatNumber(project.reward_min) + " ~ \u00A5" + TransformUtil.formatNumber(project.reward_max);
                    project.working_period_convert = TransformUtil.convertDatetime(project.working_period_start_at) + " ~ " + TransformUtil.convertDatetime(project.working_period_end_at);
                });
                if (action == "getNew" || action == "onMounted") {
                    state.listProjects = result.data;
                    state.preload = false;
                }
                else {
                    state.listProjects = state.listProjects.concat(result.data);
                }
                state.totalProjects = result.total_count;
                state.loading = false;
            }).catch(function (error) {
                state.loading = false;
                state.preload = false;
            });
        }
        function replaceByDefault(e) {
            e.target.src = noPhoto;
        }
        function dataTypesString(project) {
            return project.data_types.map(function (dataType) { return dataType.name; }).join('、') || '-';
        }
        onMounted(function () {
            var categorySearch = store.state.categoryIdSearch;
            if (categorySearch) {
                state.filter.categories.push(categorySearch);
                store.commit("setCategoryIdSearch", null);
            }
            getListCategories();
            var projectPerPage = localStorage.getItem("projectPerPage");
            state.projectPerPage = projectPerPage ? parseInt(projectPerPage) : 10;
            getListProjects("onMounted");
        });
        return {
            state: state,
            routePath: routePath,
            onChangeKeyword: onChangeKeyword,
            onChangeStatus: onChangeStatus,
            onChangeFilterStartDate: onChangeFilterStartDate,
            onChangeFilterEndDate: onChangeFilterEndDate,
            onChangeFilterCategories: onChangeFilterCategories,
            onToggleFilter: onToggleFilter,
            changeProjectPerPage: changeProjectPerPage,
            sortTable: sortTable,
            loadMoreProjects: loadMoreProjects,
            replaceByDefault: replaceByDefault,
            noPhoto: noPhoto,
            dataTypesString: dataTypesString
        };
    }
});
